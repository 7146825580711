@import url(https://fonts.googleapis.com/css2?family=Pavanam&family=Racing+Sans+One&display=swap);
social-share {
  display: flex;
}

a{ 
 display: block;
  height: 3em;
  width: 3em;
  background-color: var(--main-color);
  transition: var(--main-transition);
  color:white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-radius: 0.5em;
}

a i{
  font-size: 1.5em;
  text-decoration: none;
}
#quote-box {
  background: #eee;
 
  border-radius: 0.5em;
  display: flex;
  justify-content: center;
  padding: 3em;
  flex-direction: column;
  color: var(--main-color);
  max-width: 50em;
  min-width: 20em;
  margin: 1em;
}


#quote-box::after {
  content: "By Nicolas Garay";
  display: block;
  position: relative;
  bottom: -6em;
  width: 100%;
  margin: auto;
  text-align: center;
  color: white;
  font-size: 0.8em;
}

i {
  margin: 0em 0.5em;
}

.buttonBar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 2em;
  margin: 2em 0em 0em 0em;
}

#new-quote {
  background-color: var(--main-color);
  color: white;
  font-weight: bold;
  border: none;
  height: 3em;
  border-radius: 0.5em;
  padding: 0em 1.5em;
  font-size: 1em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--main-transition);
}

#text {
  font-size: 2em;
  align-self: center;
  height: auto;
  display: flex;
}

#author {
  font-family: "Racing Sans One", cursive;
  font-size: 1.4em;
  align-self: flex-end;
  padding: 1em;
}

:root{
  --main-color:#16a085 ;
  --main-transition: background-color linear 0.5s;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Pavanam", sans-serif;
}

html,body,#root {
  font-size: 16px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #16a085;
  background-color: var(--main-color);
  display: flex;
  justify-content: center;
  align-items: center;
  transition:  background-color linear 0.5s;
  transition:  var(--main-transition);
}

.App {
  width: 100%;
  height: 100%;
  
}

@media (max-width: 600px) {
  html,body {
    font-size: 12px;
  }
}

#quote-box {
  background: #eee;
 
  border-radius: 0.5em;
  display: flex;
  justify-content: center;
  padding: 3em;
  flex-direction: column;
  color: var(--main-color);
  max-width: 50em;
  min-width: 20em;
  margin: 1em;
}


#quote-box::after {
  content: "By Nicolas Garay";
  display: block;
  position: relative;
  bottom: -6em;
  width: 100%;
  margin: auto;
  text-align: center;
  color: white;
  font-size: 0.8em;
}

i {
  margin: 0em 0.5em;
}

.buttonBar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 2em;
  margin: 2em 0em 0em 0em;
}

#new-quote {
  background-color: var(--main-color);
  color: white;
  font-weight: bold;
  border: none;
  height: 3em;
  border-radius: 0.5em;
  padding: 0em 1.5em;
  font-size: 1em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--main-transition);
}

#text {
  font-size: 2em;
  align-self: center;
  height: auto;
  display: flex;
}

#author {
  font-family: "Racing Sans One", cursive;
  font-size: 1.4em;
  align-self: flex-end;
  padding: 1em;
}

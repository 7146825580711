@import url("https://fonts.googleapis.com/css2?family=Pavanam&family=Racing+Sans+One&display=swap");

:root{
  --main-color:#16a085 ;
  --main-transition: background-color linear 0.5s;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Pavanam", sans-serif;
}

html,body,#root {
  font-size: 16px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: var(--main-color);
  display: flex;
  justify-content: center;
  align-items: center;
  transition:  var(--main-transition);
}

.App {
  width: 100%;
  height: 100%;
  
}

@media (max-width: 600px) {
  html,body {
    font-size: 12px;
  }
}